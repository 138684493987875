import React, { useEffect, useState, useRef, useCallback } from 'react'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import ContentItem from './contentItem'
import CommentBox from '../../../components/commentBox'
import TopLevelComment from '../../../components/topLevelComment'
import ToggleSlider from '../../../components/toggleSlider'
import ListPagination from './component/listPagination'
import { message, Tooltip, Modal } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import {
  getTeam,
  getPortfolios,
  getProjects,
  getAchievement,
  getVisitHistory,
  getFavoritesList,
  getUserInfo,
  getOwnPortfolio,
  toggleComment,
  getAccountComment,
  getReplyComment,
  getSepFloorComment,
  getSepFloorReplies,
  getProjectReport,
} from './../../../utils/request'
import { getFollowPortfolios } from './../../../utils/api/account'
import { getProgramPlatformHash } from '../../../utils/api/programPlatform'
import { secretImageUrl, scratchURL, numFormat } from './../../../utils'
import { setUserInfo } from '../../../store/actions'
// import { useGetProjects } from '../../project'
// import { useGetCourse } from '../../course'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const AccountHome = ({ history, ...props }) => {
  const dispatch = useDispatch()
  const [targetUsername, setTargetUserName] = useState(
    props.match.params.username,
  )
  // const maxDataSize = props.match.params.username ? 18 : 17;
  const maxDataSize = 19
  const [targetUserInfo, setTargetUserInfo] = useState(null)
  const userInfo = useSelector((state) => state.userInfo)
  const isTeacher = useSelector((state) => state.isTeacher)
  const isStudent =
    (userInfo && userInfo.user_type === 'student') ||
    (targetUserInfo && targetUserInfo.user_type === 'student')
  const achievementRef = useRef()
  const paginationRef = useRef()
  const sliderRef = useRef()
  // const carousel = useRef();

  const [isMySelf] = useState(
    props.match.params.username === userInfo?.username,
  ) // eslint-disable-line
  const [teamData, setTeamData] = useState([])
  const [teamCurrent, setTeamCurrent] = useState(1) // eslint-disable-line
  const [teamCount, setTeamCount] = useState(0)

  const [projectData, setProjectData] = useState([])
  const [projectCurrent, setProjectCurrent] = useState(1) // eslint-disable-line
  const [projectCount, setProjectCount] = useState(0)

  const [ownPortfolioData, setOwnPortfolioData] = useState([]) // eslint-disable-line
  const [ownPortfolioCount, setOwnPortfolioCount] = useState(0) // eslint-disable-line

  const [followPortfolioData, setFollowPortfolioData] = useState([]) // eslint-disable-line
  const [followPortfolioCount, setFollowPortfolioCount] = useState(0) // eslint-disable-line

  const [portfolioData, setPortfolioData] = useState([])
  const [portfolioCurrent, setPortfolioCurrent] = useState(1) // eslint-disable-line
  const [portfolioCount, setPortfolioCount] = useState(0)

  const [visitHistoryData, setVisitHistoryData] = useState([])
  const [visitHistoryCurrent, setVisitHistoryCurrent] = useState(1) // eslint-disable-line
  const [visitHistoryCount, setVisitHistoryCount] = useState(0)

  const [favoritesListData, setFavoritesListData] = useState([])
  const [favoritesListCurrent, setFavoritesListCurrent] = useState(1) // eslint-disable-line
  const [favoritesListCount, setFavoritesListCount] = useState(0)

  const [achievement, setAchievement] = useState()

  const [comment, setComment] = useState('')

  const [comments, setComments] = useState([])
  const [commentsPage, setCommentsPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [replies, setReplies] = useState({})
  const [teamIndex, setTeamIndex] = useState(0)

  const [projectReport, setProjectReport] = useState(null)
  const [showPlatform, setShowPlatform] = useState(false)
  
  const service_validity = useSelector((state) => state.service_validity)
  const campus = useSelector((state) => state.campus)
  const isValidityAbled = service_validity === 3 || service_validity === 4

  const settings = {
    accessibility: false, // 启用Tab键和箭头键导航
    arrows: false,
    draggable: false, // 鼠标拖动
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const commentHashPrefix = '#comments-'

  const [singleCommentId] = useState(
    window.location.hash.indexOf(commentHashPrefix) !== -1 &&
      parseInt(window.location.hash.replace(commentHashPrefix, ''), 10),
  )

  const toggleUserComment = useCallback(() => {
    toggleComment(!userInfo.comment_open)
      .then((res) => {
        dispatch(setUserInfo(res.data))
      })
      .catch((e) => {
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getReplies = (ids, offset, limit = 3) => {
    let promiseArr = []
    let replies = {}
    if (ids.length) {
      promiseArr = ids.map((id) => getReplyComment(id, { limit, offset }))
      Promise.all(promiseArr).then((resArr) => {
        resArr.forEach((item, index) => {
          replies[ids[index]] = item.data
        })

        setComments((prev) =>
          prev.map((comment) => {
            if (replies[comment.id]) {
              return {
                ...comment,
                moreReplyLoad: Object.is(replies[comment.id].length, limit),
              }
            }
            return comment
          }),
        )
        setReplies((prev) => {
          Object.keys(replies).forEach((id) => {
            if (prev[id]) {
              replies[id] = [...prev[id], ...replies[id]]
            }
          })
          return {
            ...prev,
            ...replies,
          }
        })
      })
    }
  }

  const handleDelete = useCallback(
    (id) => {
      const idx = comments.findIndex((comment) => comment.id === id)
      let pid = null
      if (idx > -1) {
        pid = id
        const newComments = comments.slice(0)
        newComments.splice(idx, 1)
        setComments(newComments)
        setReplies((prev) => ({
          ...prev,
          [id]: [],
        }))
      } else {
        for (let key in replies) {
          const idx = replies[key].findIndex((reply) => reply.id === id)
          if (idx > -1) {
            pid = key
            let newReply = replies[key].slice(0)
            newReply.splice(idx, 1)
            setReplies((prev) => ({
              ...prev,
              [key]: newReply,
            }))
          }
        }
      }
      let offset = replies[pid]?.length - 1
      getReplies([pid], offset, 1)
    },
    [comments, replies],
  )
  const getSingleReplies = useCallback((pid, cid) => {
    getSepFloorReplies({ parent_id: pid, comment_id: cid }).then((res) => {
      let replies = res.data || []
      setReplies((prev) => ({
        [pid]: [...prev[pid], ...replies],
      }))
      setComments((prev) => [
        {
          ...prev[0],
          moreReplyLoad: false,
        },
      ])
    })
  }, [])

  useEffect(() => {
      // eslint-disable-next-line
    setShowPlatform(userInfo?.organization_data.oj_platform === 2 && userInfo?.team_data.oj_platform === 2 || userInfo?.organization_data.exam_platform === 2 && userInfo?.team_data.exam_platform === 2) 
      // eslint-disable-next-line
  }, [isTeacher, isStudent])

  useEffect(() => {
    if (JSON.stringify((targetUserInfo || userInfo)?.organization_data) !== '{}') {
      getProjectReport({ user_name: targetUsername }).then((res) => {
        setProjectReport(res.data)
      })
    }
  }, [targetUserInfo, targetUsername, userInfo])
  useEffect(() => {
    setTargetUserName(props.match.params.username)
  }, [props, props.match.params.username])
  useEffect(() => {
    if (userInfo && isMySelf) {
      let url = '/account/home'
      if (singleCommentId) {
        url += `/${commentHashPrefix}${singleCommentId}`
      }
      history.replace(url)
      // setInterval(() => {
      // window.location.reload();
      // }, 1500);
    }
  }, [history, isMySelf, userInfo, singleCommentId])

  useEffect(() => {
    if (targetUsername) {
      getUserInfo(targetUsername)
        .then((res) => {
          if (
            Object.is(res.data.user_type, 'student') &&
            !res.data.student_name
          ) {
            message.warning({
              content: '当前账户已被注销',
              duration: 0,
              top: 200,
            })
          }
          console.log('data', res.data)
          setTargetUserInfo(res.data)
        })
        .catch((e) => {
          // console.log('无用户数据的bug: ', e.response);
          message.warning(`无用户 ${targetUsername} 数据`)
          // history.push('/account/home')
        })
    }
  }, [targetUsername])

  useEffect(() => {
    getTeam({ page_size: maxDataSize, page: 1 }, targetUsername).then((res) => {
      setTeamData(
        res.data.results.map((item, index) => ({
          ...item,
          cover: secretImageUrl + item.cover.replace('covers/', ''),
        })),
      )

      setTeamCount(Math.min(res.data.count, maxDataSize))
    })
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getProjects({ page_size: maxDataSize, page: 1 }, targetUsername).then(
      (res) => {
        setProjectData(res.data.results)

        setProjectCount(res.data.count)
      },
    )
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getOwnPortfolio({ offset: 0, limit: maxDataSize }, targetUsername).then(
      (res) => {
        setOwnPortfolioData(targetUsername ? res.data.results : res.data)
        setOwnPortfolioCount(targetUsername ? res.data.count : res.data.length)
      },
    )
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getFollowPortfolios({ offset: 0, limit: maxDataSize }, targetUsername).then(
      (res) => {
        setFollowPortfolioData(targetUsername ? res.data.results : res.data)
        setFollowPortfolioCount(
          targetUsername ? res.data.count : res.data.length,
        )
      },
    )
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    if (targetUsername) return
    getPortfolios({ page_size: maxDataSize, page: 1 }).then((res) => {
      setPortfolioData(
        res.data.results.map((item, index) => ({
          ...item,
          cover: secretImageUrl + item.cover,
          team_cover: secretImageUrl + item.team_cover,
        })),
      )
      setPortfolioCount(Math.min(res.data.count, maxDataSize))
    })
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getVisitHistory({ page_size: maxDataSize, page: 1 }, targetUsername).then(
      (res) => {
        // res.data.results = res.data.results
        res.data.results = res.data.results.map((item) => {
          let url
          if (item.resourcetype === 'CourseHistory') {
            url = `/course/${item.course}/${item.unit || 0}`
          } else if (item.resourcetype === 'CourseProjectHistory') {
            url = `/project/${item.project}`
          }

          return {
            ...item,
            url,
          }
        })
        setVisitHistoryData(res.data.results)
        setVisitHistoryCount(Math.min(res.data.count, maxDataSize))

        // setVisitHistoryCount(res.data.count)
      },
    )
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getFavoritesList({ page_size: maxDataSize, page: 1 }, targetUsername).then(
      (res) => {
        setFavoritesListData(
          res.data.results.map((item) => ({
            ...item,
            ...{ cover: item.image },
          })),
        )
        setFavoritesListCount(Math.min(res.data.count, maxDataSize))
      },
    )
  }, [targetUsername, maxDataSize])

  useEffect(() => {
    getAchievement(targetUsername || userInfo?.username).then((res) => {
      setAchievement(res.data)
    })
  }, [userInfo, targetUsername])

  useEffect(() => {
    const fetchData = (page) => {
      const limit = 10
      const offset = (page - 1) * limit
      const users = targetUsername || userInfo?.username
      getAccountComment(users, { offset, limit })
        .then((res) => {
          setComments((comments) => comments.concat(res.data))
          if (res.data < limit) {
            setHasMore(false)
          }
          let ids = res.data.map((comment) => comment.id)
          return ids
        })
        .then((ids) => {
          getReplies(ids, 0)
        })
    }
    const fetchSingleData = (id) => {
      getSepFloorComment(id)
        .then((res) => {
          const data = res.data
          const replies = data.children
          delete res.data.children
          if (replies && replies.length) {
            setReplies((prev) => ({ ...prev, [res.data.id]: replies }))
          }
          data.moreReplyLoad = !!replies.length
          setComments([data])
        })
        .catch((err) => {
          message.warning('该留言已被删除')
        })
    }
    if (singleCommentId) {
      fetchSingleData(singleCommentId)
    } else {
      fetchData(commentsPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsPage, singleCommentId])

  useEffect(() => {
    setComments([])
    setCommentsPage(1)
    setReplies({})
  }, [targetUsername])

  const renderCommentBox = () => {
    return userInfo ? (
      <CommentBox
        comment={comment}
        userInfo={userInfo}
        handleChange={(e) => e.target && setComment(e.target.value)}
        handleCancel={() => setComment('')}
        onAddComment={(data) => {
          setComment('')
          setComments((prev) => [data, ...prev])
        }}
        userName={targetUsername || userInfo?.username}
        type="smallcomment"
      />
    ) : null
  }
  //
  const getPage = (val = 1) => {
    teamIndex < val - 1
      ? sliderRef.current.slickNext()
      : sliderRef.current.slickPrev()
    // teamIndex < val - 1 ? carousel.current.next() : carousel.current.prev();
    setTeamIndex(val - 1)
  }

  const compareLevel = () => {
    const levelMap = new Map([
      ['student', 1],
      ['teacher', 2],
      ['manager', 3],
      ['manager|teacher', 3],
    ])
    // console.log(targetUserInfo, user)
    if (
      !targetUsername ||
      (targetUsername &&
        levelMap.get(userInfo?.user_type) >
          levelMap.get(targetUserInfo?.user_type))
    )
      return true
    return false
  }

  const validateOrgExpDate = () => {
    const info = targetUsername ? targetUserInfo : userInfo
    if (
      moment(info?.organization_data?.end_date).format('YYYYMMDD') <
      moment().format('YYYYMMDD')
    ) {
      const title =
        info.user_type.indexOf('manager') > -1
          ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118。'
          : info.user_type.indexOf('teacher') > -1
          ? '英荔教学云服务已到期，如需继续使用，请告知校区管理员。'
          : '英荔教学云服务已到期。如需继续使用，请告知老师或校区管理员。'
      Modal.info({
        title,
        centered: true,
        icon: <ExclamationCircleFilled style={{ color: '#faad14' }} />,
      })
    } else {
      !targetUserInfo
        ? history.push('/account/datacenter')
        : history.push(`/account/datacenter/${targetUsername}`)
    }
  }

  const programPlatformURL = useRef(
      process.env.NODE_ENV === 'development'
          ? 'http://localhost:8080'
          : window.location.host.includes('xyz')
              ? 'https://ksbackend.eliteu.xyz'
              : 'https://codebank.aimaker.space'
  )
  const goToProgramPlatform = async () => {
    const { data } = await getProgramPlatformHash()
    // window.location.replace(programPlatformURL.current + '?hash=' + data.encrypt_text)
    window.location.href = programPlatformURL.current + '?hash=' + data.encrypt_text
  }


  // 客观题
  const objectiveURL = useRef(
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:3200'
        : window.location.host.includes('xyz')
            ? 'https://kg.eliteu.xyz'
            : 'https://quizbank.aimaker.space'
)
  const goToObjective = async () => {
    const { data } = await getProgramPlatformHash()
    if (data.encrypt_text) {
      // window.location.replace(objectiveURL.current + '/login?token=' + data.encrypt_text)
      window.location.href = objectiveURL.current + '/login?token=' + data.encrypt_text
    }
  }

  return (
    <>
      <div id="accountHome" className="container">
        <section className="userContainer">
          <section className="userSide">
            <section className="userPanel">
              <div className="avatar">
                <Link
                  className={targetUserInfo ? 'disable' : ''}
                  to="/account/user"
                >
                  <img
                    style={{objectFit: "cover"}}
                    src={(targetUserInfo || userInfo)?.avatar_url}
                    alt="头像"
                  />
                </Link>
              </div>
              {/*=== before the change ===*/}
              {/* <div className="certificateBar">
               {!targetUsername && isTeacher ? (
                <>
                  <img src="/image/icon-certificate.png" alt="认证" />
                  <p className="text">已认证机构</p>
                </>
              ) : Object.is(targetUserInfo?.user_type, "teacher") ? (
                <>
                  <img src="/image/icon-certificate.png" alt="认证" />
                  <p className="text">已认证机构</p>
                </>
              ) : null}
            </div>*/}
              <div className="username">
                <Link
                  className={targetUserInfo ? 'disable' : ''}
                  to="/account/user"
                >
                  {(targetUserInfo || userInfo)?.student_name ||
                    (targetUserInfo || userInfo)?.name ||
                    (targetUserInfo || userInfo)?.username}
                </Link>
              </div>
              {(targetUserInfo || userInfo)?.student_name ? (
                <div className="name">
                  {(targetUserInfo || userInfo)?.username}
                </div>
              ) : null}
              <div className="description">
                <Link
                  className={targetUserInfo ? 'disable' : ''}
                  to="/account/user"
                >
                  {(targetUserInfo || userInfo)?.description ||
                    'Ta 很神秘，还没留下个人介绍'}
                </Link>
              </div>
              {JSON.stringify(
                (targetUserInfo || userInfo)?.organization_data,
              ) !== '{}' ? (
                <div className="org_mark">
                    {
                      (targetUserInfo || userInfo)?.organization_data.org_type === '0' ?
                        <img
                          src="/image/org_logo0.png"
                          alt="question"
                          className="tip"
                          width="25px"
                          height="28px"
                        /> : ((targetUserInfo || userInfo)?.organization_data.org_type === '1' ? <img
                          src="/image/org_logo1.png"
                          alt="question"
                          className="tip"
                          width="25px"
                          height="28px"
                        /> :
                          <img
                            src="/image/org_logo2.png"
                            alt="question"
                            className="tip"
                            width="25px"
                            height="28px"
                          />)
                    }
                  <p className="text_mark">
                    {(targetUserInfo || userInfo)?.organization_data
                      ?.text_mark ||
                      (targetUserInfo || userInfo)?.organization_data?.name}
                  </p>
                </div>
              ) : (
                <div className="line"></div>
              )}

              {(targetUsername && targetUserInfo) || !targetUsername
                ? (!targetUsername && isTeacher) ||
                  targetUserInfo?.user_type === 'teacher'
                  ? null && (
                      <>
                        <div className="achievementTitle">社区成就</div>
                        <div className="details" ref={achievementRef}>
                          <div className="share detail">
                            <div className="title">分享</div>
                            <Tooltip
                              placement="top"
                              title={achievement?.shared}
                              getPopupContainer={() => achievementRef.current}
                            >
                              <div className="num">
                                {achievement?.shared
                                  ? numFormat(achievement.shared)
                                  : 0}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="view detail">
                            <div className="title">浏览</div>
                            <Tooltip
                              placement="top"
                              title={achievement?.views}
                              getPopupContainer={() => achievementRef.current}
                            >
                              <div className="num">
                                {achievement?.views
                                  ? numFormat(achievement.views)
                                  : 0}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="fav detail">
                            <div className="title">收藏</div>
                            <Tooltip
                              placement="top"
                              title={achievement?.favoriates}
                              getPopupContainer={() => achievementRef.current}
                            >
                              <div className="num">
                                {achievement?.favoriates
                                  ? numFormat(achievement.favoriates)
                                  : 0}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="like detail">
                            <div className="title">赞</div>
                            <Tooltip
                              placement="top"
                              title={achievement?.loves}
                              getPopupContainer={() => achievementRef.current}
                            >
                              <div className="num">
                                {achievement?.loves
                                  ? numFormat(achievement.loves)
                                  : 0}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    )
                  : null && (
                      <>
                        {teamData && teamData.length > 0 && (
                          <>
                            <Slider {...settings} ref={sliderRef}>
                              {teamData.map((item, idx) => (
                                <div className="teamInfo" key={idx}>
                                  <div className="detail">
                                    <div className="image">
                                      <img
                                        src={item?.cover}
                                        alt={item?.name || ''}
                                      />
                                    </div>
                                    <div className="metaData">
                                      <div className="name">{item.name}</div>
                                      <div className="num">
                                        {item.num_student}人{' '}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="description">
                                    {item.description.replace(/ /g, '\u00a0')}
                                  </div>
                                </div>
                              ))}
                            </Slider>
                            {/* Carousel 会有顺序的bug */}
                            {/* // <Carousel dots={false} ref={carousel}>
                      //   {teamData.map((item, idx) => (
                      //     <div className="teamInfo" key={idx}>
                      //       <div className="detail">
                      //         <div className="image">
                      //           <img src={item?.cover} alt={item?.name || ''} />
                      //         </div>
                      //         <div className="metaData">
                      //           <div className="name">{item.name}</div>
                      //           <div className="num">{item.num_student}人 </div>
                      //         </div>
                      //       </div>
                      //       <div className="description">
                      //         {item.description.replace(/ /g, '\u00a0')}
                      //       </div>
                      //     </div>
                      //   ))}
                      // </Carousel> */}

                            <ListPagination
                              total={teamData.length}
                              getPage={getPage}
                              ref={paginationRef}
                              speed={settings.speed}
                            />
                            <div
                              className="line"
                              style={{ marginTop: '18px' }}
                            ></div>
                          </>
                        )}
                      </>
                    )
                : null}
            </section>

            {showPlatform &&
              <div className="programPlatform">
                <h2>题库</h2>
                {userInfo?.organization_data.oj_platform === 2 && userInfo?.team_data.oj_platform === 2 ?
                  <div className="platform program" onClick={goToProgramPlatform}>
                    <div className="lt">
                      <b className="title">编程题</b>
                      <div className="text">信息学奥赛 Python C++</div>
                    </div>
                    <div className="rt">
                      <img
                        width="6"
                        height="10"
                        src="/image/right-arrow-program-platform.svg"
                        alt="add"
                      />
                    </div>
                  </div> : null
                }
                {userInfo?.organization_data.exam_platform === 2 && userInfo?.team_data.exam_platform === 2 ?
                  <div className="platform objective" onClick={goToObjective}>
                    <div className="lt">
                      <b className="title">客观题</b>
                      <div className="text">等级考试 蓝桥杯</div>
                    </div>
                    <div className="rt">
                      <img
                        width="6"
                        height="10"
                        src="/image/right-arrow-program-platform.svg"
                        alt="add"
                      />
                    </div>
                  </div> : null
                }
              </div>
            }

            {JSON.stringify((targetUserInfo || userInfo)?.organization_data) !==
              '{}' && projectReport ? (
              <section className="dataCenter">
                <div className="dataList">
                  <div className="dataItem">
                    <span className="text">
                      {projectReport?.active_time?.toLocaleString() || 0}
                    </span>
                    <span className="title">在线时长/h</span>
                  </div>
                  {!isStudent ? (
                    <>
                      <div className="dataItem">
                        <span className="text">
                          {projectReport?.course_time?.toLocaleString() || 0}
                        </span>
                        <span className="title">看课时长/h</span>
                      </div>
                      <div className="dataItem">
                        <span className="text">
                          {projectReport?.course_num || 0}
                          <span>/{projectReport?.all_course_num || 0}</span>
                        </span>
                        <span className="title">已看课程</span>
                      </div>
                    </>
                  ) : null}
                  <div className="dataItem">
                    <span className="text">
                      {projectReport?.creation_time?.toLocaleString() || 0}
                    </span>
                    <span className="title">创作时长/h</span>
                  </div>
                  <div className="dataItem">
                    <span className="text">
                      {projectReport?.project_num || 0}
                    </span>
                    <span className="title">创作作品</span>
                  </div>
                  <div className="dataItem">
                    <span className="text">
                      {projectReport?.extend_num || 0}
                      <span>/{projectReport?.all_extend_num || 0}</span>
                    </span>
                    <span className="title">使用扩展数</span>
                  </div>
                </div>
                {compareLevel() &&
                userInfo?.organization_data?.personal_data_center ? (
                  <div className="moreBtn" onClick={validateOrgExpDate}>
                    查看全部
                    <img
                      width="6"
                      style={{ marginBottom: '3px', marginLeft: '5px' }}
                      height="10"
                      src="/image/icon-arrow.svg"
                      alt="add"
                    />
                  </div>
                ) : null}
              </section>
            ) : null}
          </section>
          <section className="userContent">
            <ContentItem
              data={projectData}
              count={projectCount}
              onChange={(val) => setProjectCurrent(val)}
              showAllUrl={'/manage/project'}
              createUrl={{ href: scratchURL + '/projects/editor/' }}
              targetUser={targetUserInfo}
              title={'我的作品'}
              rectangle
            ></ContentItem>

            <ContentItem
              data={visitHistoryData}
              count={visitHistoryCount}
              onChange={(val) => setVisitHistoryCurrent(val)}
              title={'最近学习'}
              showAllUrl={'/learnhistory'}
              targetUser={targetUserInfo}
              createUrl={{ href: scratchURL }}
              rectangle
            ></ContentItem>
            {/* 样式有莫名错误 */}
            {/* {!targetUsername || targetUsername
              ? !targetUserInfo && (
                  <ContentItem
                    data={ownPortfolioData}
                    count={ownPortfolioCount}
                    showAllUrl={'/manage/portfolio'}
                    targetUser={targetUserInfo}
                    title={'我的作品集'}
                    createUrl={async () => {
                      if (teamData.length === 0) {
                        message.warn('请先创建团队，再创建作品集');
                      } else {
                        history.push('/manage/portfolio?create=new');
                      }
                    }}
                    // createUrl={
                    //   '/manage/portfolio?create=new' ||
                    //   '/manage/ownportfolio?create=new'
                    // }
                  />
                )
              : null} */}

            {!targetUsername || targetUsername
              ? !targetUserInfo && (
                  <ContentItem
                    createUrl={() => {
                      if (teamData.length > 0) {
                        history.push('/manage/portfolio?create=new')
                        return
                      }
                      message.warn('请先创建团队，再创建作品集')
                    }}
                    // userInfo={userInfo}
                    data={portfolioData}
                    count={portfolioCount}
                    onChange={(val) => setPortfolioCurrent(val)}
                    showAllUrl={'/manage/portfolio'}
                    title={'我的作品集'}
                  ></ContentItem>
                )
              : null}

            {!targetUsername ? (
              <ContentItem
                data={teamData}
                count={teamCount}
                showAllUrl={'/manage/team'}
                onChange={(val) => setTeamCurrent(val)}
                createUrl={'/manage/team?create=new'}
                title={'我的团队'}
                clickable={isTeacher && !isValidityAbled}
              ></ContentItem>
            ) : null}

            <ContentItem
              // data={[...Array(18).keys()].map((item, index) => ({title: `项目名${item}`, name: '姓名'}))}
              data={favoritesListData}
              // createUrl={{ href: scratchURL }}
              createUrl={'/manage/portfolio'}
              count={favoritesListCount}
              onChange={(val) => setFavoritesListCurrent(val)}
              showAllUrl={'/favorites'}
              targetUser={targetUserInfo}
              title={'我的收藏'}
              rectangle
            ></ContentItem>

            {/* {!targetUserInfo && (
              <ContentItem
                data={followPortfolioData}
                count={followPortfolioCount}
                showAllUrl={'/account/follow'}
                targetUser={targetUserInfo}
                title={'我关注的作品集'}
                createUrl={{ href: `${scratchURL}/explore/studios/all` }}
              />
            )} */}
          </section>
        </section>
        {/* { (targetUserInfo || userInfo)?.user_type === 'teacher' ? ( */}
        { campus.length === 0 ? (
          <section className="comments-container">
            <div class="comments-box">
              <div className="comments-header">
                <h3 className="title">留言板</h3>
                {!targetUsername ? (
                  <div>
                    <span>
                      {userInfo?.comment_open ? '评论打开' : '评论关闭'}
                    </span>
                    <ToggleSlider
                      checked={userInfo?.comment_open}
                      onChange={toggleUserComment}
                    />
                  </div>
                ) : null}
              </div>
              {(targetUserInfo || userInfo)?.comment_open ? (
                renderCommentBox()
              ) : (
                <>
                  <div className="comments-turned-off">
                    抱歉，该用户的留言功能已关闭
                  </div>
                </>
              )}
              {comments.length ? (
                <div className="comment-list">
                  {comments.map((comment) => (
                    <TopLevelComment
                      hightlightId={singleCommentId}
                      key={comment.id}
                      comment={comment}
                      replies={replies[comment.id] || []}
                      userName={targetUsername || userInfo?.username}
                      onAddReply={(data) => {
                        setReplies((prev) => {
                          if (prev[comment.id]) {
                            return {
                              ...prev,
                              [comment.id]: [data, ...prev[comment.id]],
                            }
                          }
                          return {
                            ...prev,
                            [comment.id]: [data],
                          }
                        })
                      }}
                      onLoadMore={(id, offset) => getReplies([id], offset)}
                      onLoadSingle={getSingleReplies}
                      moreReplyLoad={comment.moreReplyLoad}
                      canDelete={!targetUsername && userInfo}
                      canReply={
                        (targetUserInfo || userInfo)?.comment_open && isTeacher
                      }
                      handleDelete={handleDelete}
                    />
                  ))}
                  {comments.length && hasMore ? (
                    <div
                      className="load-more"
                      onClick={() => {
                        if (singleCommentId) {
                          history.push(history.location.pathname)
                          window.location.reload()
                        } else {
                          setCommentsPage((page) => page + 1)
                        }
                      }}
                    >
                      {singleCommentId ? '查看全部留言' : '加载更多'}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </section>
        ) : null}
      </div>
    </>
  )
}

export default withRouter(AccountHome)
